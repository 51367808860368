<ng-container *ngIf="dataMap[type] as data">
  <ng-container *ngIf="!value">
    <div
      *ngIf="mode === 'drop'"
      class="upload--drop"
      (dragover)="onDragOver($event)"
      (drop)="onFileDrop($event)"
      (click)="onBodyClicked()"
    >
      <div class="upload__image">
        <i
          *ngIf="!error"
          nz-icon
          [nzType]="data.icon"
        ></i>
      </div>
      <div class="upload__title">
        <span *ngIf="!value && !error">{{ data.title }}</span>
        <span *ngIf="value && !error">{{ value?.name }}</span>
        <ng-container *ngIf="error">
          <div class="error">Upload failed</div>
          <app-button
            [small]="true"
            [type]="'danger'"
            (btnClick)="onRemoveFile()"
          >
            Try again
          </app-button>
        </ng-container>
      </div>
      <div
        *ngIf="!error"
        class="upload__note"
      >
        <span *ngIf="!uploadProgress">{{ data.note }}</span>
        <div *ngIf="!uploadProgress">Max file size is {{ data.unitsTransform(data.uploadLimit) }} MB</div>
        <span *ngIf="uploadProgress">
          <span class="uploaded">
            {{ data.unitsTransform(uploadProgress?.loaded ?? 0) | number: '1.0-2' }} {{ data.units }}
          </span>
          /{{ data.unitsTransform(uploadProgress?.total ?? 0) | number: '1.0-2' }} {{ data.units }}
        </span>
      </div>
      <nz-progress
        *ngIf="uploadProgress && !error"
        [nzPercent]="uploadProgress?.loaded ?? 0 | percentValue: uploadProgress?.total ?? 0"
        [nzShowInfo]="false"
        [nzStrokeWidth]="2"
      ></nz-progress>
    </div>

    <div
      *ngIf="mode === 'select'"
      class="upload--select uploaded__body"
    >
      <div class="uploaded__left">
        {{ dataMap.image.note }}
      </div>
      <div class="uploaded__action">
        <app-button
          [small]="true"
          [type]="'primary'"
          (btnClick)="onFileReplace()"
        >
          Browse files
        </app-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="value">
    <app-file-preview
      [thumbnailPreview]="thumbnailPreview"
      [value]="value"
      [data]="data"
      [allowReplace]="allowReplace"
      (removeFile)="onRemoveFile()"
      (fileReplace)="onFileReplace()"
    ></app-file-preview>
  </ng-container>

  <input
    #fileInput
    style="display: none"
    type="file"
    [accept]="data.accept"
    (change)="onFileInput($event)"
    (click)="onInputClick($event)"
  />
</ng-container>
