import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  NgModule,
  NgZone,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { Observable } from 'rxjs';
import { UploadProgressResponse } from 'src/app/core/services/api/media.service';
import { environment } from '@environments/environment';
import { MessageService } from '@app/core/services/message.service';
import { ValueAccessorComponent } from '@app/shared/components/inputs/value-accessor.componet';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Media } from '@app/core/model/Media';
import { MediaPathPipeModule } from '@app/shared/pipes/media-path.pipe';
import { finalize, tap } from 'rxjs/operators';
import { FilePreviewComponent } from '@app/shared/components/upload/file-preview/file-preview.component';
import { MediaDataMap } from '@app/shared/components/upload/mediaDataMap';

import { PercentPipeModule } from '../../pipes/percent.pipe';
import { ButtonModule } from '../button/button.component';

export interface FileData {
  id: string;
  name: string;
  size: number;
  url?: string;
}

export type UploadMethod = (files: File) => Observable<UploadProgressResponse>;

@Component({
  selector: 'app-upload',
  templateUrl: 'upload.component.html',
  styleUrls: ['upload.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadComponent),
      multi: true
    }
  ]
})
export class UploadComponent extends ValueAccessorComponent<FileData> implements OnChanges {
  @ViewChild('fileInput') public fileInput!: ElementRef<HTMLInputElement>;
  @Input() public type: 'image' | 'video' = 'image';
  @Input() public mode: 'drop' | 'select' = 'drop';
  @Input() public allowReplace?: boolean;
  @Input() public uploadMethod?: UploadMethod;
  @Input() public thumbnailPreview?: boolean;
  @Input() public progress$: Observable<UploadProgressResponse>;

  public value: FileData | null;
  public error: boolean;

  public dataMap: MediaDataMap = {
    image: {
      title: 'Drop your image here or click to browse',
      note: 'File must be .png, .jpg, .jpeg',
      icon: 'icons:upload-image',
      accept: 'image/png,image/jpg,image/jpeg',
      units: 'mb',
      uploadLimit: 10 * 1000 * 1000,
      unitsTransform: (size: number) => size / 1000 / 1000
    },
    video: {
      title: 'Drop your video here or click to browse',
      note: 'File must be .mp4',
      icon: 'icons:upload-video',
      accept: 'video/mp4',
      units: 'mb',
      uploadLimit: 100 * 1000 * 1000,
      unitsTransform: (size: number) => size / 1000 / 1000
    }
  };
  public uploadProgress?: { total: number; loaded: number };
  public baseUrl = environment.baseUrl;
  private uploadRunning: boolean;

  constructor(
    private messageService: MessageService,
    inj: Injector,
    private ngZone: NgZone
  ) {
    super(inj);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.progress$?.currentValue) {
      changes.progress$.currentValue
        .pipe(
          tap(() => (this.uploadRunning = true)),
          finalize(() => (this.uploadRunning = false))
        )
        .subscribe((event) => {
          this.processProgress(event);
        });
    }
  }

  /**
   * Handle selected file in file input
   *
   * @param ev
   */
  public onFileInput(ev: any) {
    const [file] = ev.target.files;
    this.onFileSelect(file);
  }

  public onBodyClicked() {
    this.openFileInput();
  }

  public onFileReplace() {
    this.openFileInput();
  }

  public onInputClick(ev: any) {
    ev.stopPropagation();
  }

  /**
   * Handle file removed from component
   */
  public onRemoveFile() {
    this.ngZone.run(() => {
      this.fileInput.nativeElement.value = '';
      this.value = null;
      this.uploadProgress = undefined;
      this.error = false;
      this.change(this.value);
    });
  }

  /**
   * Handle file dropped in component.
   *
   * @param ev
   */
  public onFileDrop(ev: DragEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    const file = ev.dataTransfer?.files?.[0];
    if (!file) {
      return;
    }
    if (!this.isFileSupported(file)) {
      this.messageService.error('Unsupported file format');
      return;
    }
    this.onFileSelect(file);
  }

  public onDragOver(ev: DragEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    ev.dataTransfer.dropEffect = 'copy';
  }

  private isFileSupported(file: File): boolean {
    return this.dataMap[this.type].accept.split(',').some((format) => format === file.type);
  }

  private uploadFile(file: File) {
    this.uploadMethod?.(file)
      ?.pipe(
        tap(() => (this.uploadRunning = true)),
        finalize(() => (this.uploadRunning = false))
      )
      .subscribe({
        next: (event: UploadProgressResponse) => {
          this.processProgress(event);
        },
        error: () => {
          this.error = true;
          this.messageService.error('Error: Unable to upload media');
        }
      });
  }

  private onFileSelect(file: File) {
    if (this.uploadRunning) return;
    const data = this.dataMap[this.type];
    if (file.size > data.uploadLimit) {
      this.messageService.error(
        `Upload limit exceeded. Max file size is ${Math.floor(data.uploadLimit / 1000 / 1000)}MB`
      );
      return;
    }

    if (this.value?.name) {
      this.resetFileInput();
      this.value = null;
      this.change(this.value);
    }

    this.uploadFile(file);
  }

  private resetFileInput() {
    this.fileInput.nativeElement.value = '';
    this.value = null;
    this.uploadProgress = undefined;
  }

  private openFileInput() {
    if (this.uploadRunning) return;
    this.fileInput?.nativeElement?.click?.();
  }

  private processProgress(event: UploadProgressResponse) {
    this.value = null;
    if (event.progress) {
      const { loaded, total } = event.progress;
      this.uploadProgress = {
        loaded: loaded ?? 0,
        total: total ?? 0
      };
    }

    if (event.response) {
      this.uploadProgress = undefined;
      const resBody: Media = event.response.body;
      setTimeout(() => {
        this.value = {
          id: resBody._id,
          name: resBody.name,
          size: Number(resBody.size)
        };
        this.change(this.value);
      });
    }
  }
}

@NgModule({
  declarations: [UploadComponent, FilePreviewComponent],
  exports: [UploadComponent],
  imports: [
    NzIconModule, //
    CommonModule,
    NzProgressModule,
    PercentPipeModule,
    ButtonModule,
    MediaPathPipeModule
  ]
})
export class UploadModule {}
