<div
  *ngIf="!thumbnailPreview"
  class="uploaded"
>
  <div class="uploaded__title">Uploaded file</div>
  <div class="uploaded__body">
    <div class="uploaded__left">
      <div
        class="uploaded__detail"
        *ngIf="value as fileData"
      >
        <i
          nz-icon
          nzType="icons:media-icon"
        ></i>
        <span class="fileName">{{ fileData?.name }}</span>
        <span class="fileSize">{{ data.unitsTransform(fileData?.size ?? 0) | number: '1.0-2' }} {{ data.units }}</span>
      </div>
    </div>
    <div class="uploaded__action">
      <i
        *ngIf="!allowReplace"
        class="clickable"
        nz-icon
        nzType="icons:close"
        (click)="removeFile.emit()"
      ></i>
      <app-button
        *ngIf="allowReplace"
        [small]="true"
        [type]="'primary'"
        (btnClick)="fileReplace.emit()"
      >
        Replace
      </app-button>
    </div>
  </div>
</div>
<div
  *ngIf="thumbnailPreview"
  class="uploaded__thumbnail"
>
  <img
    class="thumbnail__img"
    [src]="value.id | mediaPath"
    alt=""
  />
  <div
    class="thumbnail__cross clickable"
    (click)="removeFile.emit()"
  >
    <i
      nz-icon
      nzType="icons:cross"
    ></i>
  </div>
</div>
