import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileData } from '@app/shared/components/upload/upload.component';
import { DataMapItem } from '@app/shared/components/upload/mediaDataMap';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.less']
})
export class FilePreviewComponent {
  @Input() thumbnailPreview: boolean;
  @Input() value: FileData;
  @Input() data: DataMapItem;
  @Input() allowReplace: boolean;
  @Output() removeFile = new EventEmitter<void>();
  @Output() fileReplace = new EventEmitter<void>();
}
