import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentValue'
})
export class PercentPipe implements PipeTransform {
  transform(value: number, total: number): number {
    return (value * 100) / total;
  }
}

@NgModule({
  declarations: [PercentPipe],
  exports: [PercentPipe]
})
export class PercentPipeModule {}
